import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as $ from 'jquery' ;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: false,
    margin: 10,
    nav: true,
    navText: [
      "<img src='assets/images/arrowleft.png'>",
      "<img src='assets/images/arrowright.png'>"
    ],
    autoplay: false,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 11
      }
    },
    dots: false
  }

  constructor() { }

  ngOnInit(): void {
    var uri = window.location.href.toString();
    if (uri.indexOf("#") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("#"));
        window.history.replaceState({}, document.title, clean_uri);
    }

    $(".productbox").click(function(){
      $("#productpopup").addClass("showloc");
    });

    $(".close").click(function(e){
      $("#productpopup").removeClass("showloc");
      $("#productpopup").hide();
      var uri = window.location.href.toString();
      if (uri.indexOf("#") > 0) {
          var clean_uri = uri.substring(0, uri.indexOf("#"));
          window.history.replaceState({}, document.title, clean_uri);
      }
    });

    // $(document).ready(function() {
    //   $('div.owl-prev').html('<img src="assets/images/arrowleft.png" />');
    //   $('div.owl-next').html('<img src="assets/images/arrowright.png" />');
    // });
  }
}