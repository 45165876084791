<div class="screensize">
    <div class="leftside">
        <div class="header">
    <div class="navigation">
    <div class="allcatbox">
        <a [routerLink]="['/home']">
            <img src="../../assets/images/allcat.png">
            <span>Categories</span>
            </a>
    </div>
    <div class="carousel-wrap">
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/burger.png">
                    <span>Burger</span>
                    </a>
                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/pizza.png">
                    <span>Pizza</span>
                    </a>
                </div>
            </ng-template>
            
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/salad.png">
                    <span>Salad</span>
                    </a>
                </div>
            </ng-template>

            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/noodle.png">
                    <span>Noodles</span>
                    </a>
                </div>
            </ng-template>
            
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/wrap.png">
                    <span>Wraps</span>
                    </a>
                </div>
            </ng-template>
            
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/soda.png">
                    <span>Soda</span>
                    </a>
                </div>
            </ng-template>
            
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/coffee.png">
                    <span>Coffee</span>
                    </a>
                </div>
            </ng-template>
            
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/soup.png">
                    <span>Soup</span>
                    </a>
                </div>
            </ng-template>
        
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/fries.png">
                    <span>Fries</span>
                    </a>
                </div>
            </ng-template>
            
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/dessert.png">
                    <span>Desset</span>
                    </a>
                </div>
            </ng-template>
            
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/burger.png">
                    <span>Burger</span>
                    </a>
                </div>
            </ng-template>
            
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/noodle.png">
                    <span>Noodles</span>
                    </a>
                </div>
            </ng-template>
            
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/wrap.png">
                    <span>Wraps</span>
                    </a>
                </div>
            </ng-template>
            
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/pizza.png">
                    <span>Pizza</span>
                    </a>
                </div>
            </ng-template>
            
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/salad.png">
                    <span>Salad</span>
                    </a>
                </div>
            </ng-template>
            
            <ng-template carouselSlide>
                <div class="item">
                    <a href="">
                    <img src="../../assets/images/soda.png">
                    <span>Soda</span>
                    </a>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
    </div>
    </div>
        <div class="page-content">
            <div class="page-header">
                <div class="page-title">Today's Products </div>
                <div class="page-search">
                      <div class="form-input">
                        <button id="convert" class="button glyphicon glyphicon-search"><img src="../../assets/images/search.png"></button>
                        <input id="uid" type="text" class="input" value="" placeholder="Search">
                      </div>
                </div>
            </div>
        </div>
        <div class="productslider">
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/salad1.png"></span>
                <span class="productname">Chicken Salad</span>
                <span class="productprice">$4.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/ahasoda.png"></span>
                <span class="productname">Aha Soda</span>
                <span class="productprice">$2.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/bread.png"></span>
                <span class="productname">Garlic bread</span>
                <span class="productprice">$1.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/cburger.png"></span>
                <span class="productname">Chicken Burger</span>
                <span class="productprice">$3.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/tacos1.png"></span>
                <span class="productname">Tacos</span>
                <span class="productprice">$2.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/pizza1.png"></span>
                <span class="productname">Pizza</span>
                <span class="productprice">$5.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/gsalad.png"></span>
                <span class="productname">Geek Salad</span>
                <span class="productprice">$3.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/chickensalad.png"></span>
                <span class="productname">Chicken Salad</span>
                <span class="productprice">$6.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/drpepper.png"></span>
                <span class="productname">Dr Pepper</span>
                <span class="productprice">$1.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/coffee1.png"></span>
                <span class="productname">Coffee</span>
                <span class="productprice">$3.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/pasta.png"></span>
                <span class="productname">Pasta</span>
                <span class="productprice">$2.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/hotdog.png"></span>
                <span class="productname">Hot Dog</span>
                <span class="productprice">$1.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/chips.png"></span>
                <span class="productname">Chips</span>
                <span class="productprice">$2.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/noodle1.png"></span>
                <span class="productname">Noddle Soup</span>
                <span class="productprice">$2.99</span>
            </a>
            </div>
        
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/cburger.png"></span>
                <span class="productname">Chicken Burger</span>
                <span class="productprice">$3.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/colo.jpg"></span>
                <span class="productname">Cola</span>
                <span class="productprice">$2.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/panner.png"></span>
                <span class="productname">Veg Noodle</span>
                <span class="productprice">$2.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/chickenwing.png"></span>
                <span class="productname">Chicken wings</span>
                <span class="productprice">$4.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/mixsalad.png"></span>
                <span class="productname">Mix Salad</span>
                <span class="productprice">$4.59</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/pasta2.png"></span>
                <span class="productname">Pasta</span>
                <span class="productprice">$6.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/onionring.png"></span>
                <span class="productname">Onion Rings</span>
                <span class="productprice">$3.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/cburger.png"></span>
                <span class="productname">Chicken Burger</span>
                <span class="productprice">$3.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="homehome#productpopup">
                <span class="productimg"><img src="../../assets/images/ffries.png"></span>
                <span class="productname">French Fries</span>
                <span class="productprice">$2.99</span>
            </a>
            </div>
            <div class="productbox">
                <a href="home#productpopup">
                <span class="productimg"><img src="../../assets/images/salad1.png"></span>
                <span class="productname">Chicken Salad</span>
                <span class="productprice">$2.99</span>
            </a>
            </div>
        <div class="cancalleftbtn"></div>
    <div class="pagination-arrow"> 
    <div class="arrowleft"><img src="../../assets/images/arrowleft.png"></div>
    <div class="pageno">1/5</div>
    <div class="arrowright"><img src="../../assets/images/arrowright.png"></div>
    </div>	
        </div>
    </div>
    <div class="rightside">
        <div class="logo">
            <img src="../../assets/images/logo.png">
        </div>
        <div class="summery">ORDER SUMMERY</div>
        <div class="noproduct">
            <img src="../../assets/images/emptybag.png">
            <span>Choose an item<br/>from the menu <br/>to get started</span>
        </div>
    </div>
    
    <!-- working product popup -->
    <div id="productpopup" class="overlay">
        <div class="productup">
            <div class="productvariation_detail">
                <span class="close"><img src="../../assets/images/closebox.png"></span>
                <div class="largeproimg">
                    <img src="../../assets/images/salad1.png">
                </div>
                <h2>Egg & Cheese Breakfast Sandwich</h2>
                <div class="vcontent">
                    <div class="variation_detail">
                        <div class="variation_title">
                            <h5 class="variation_name">Choice of Large Side</h5>
                            <p class="variation-status">Required</p>
                        </div>
                        <div class="variation-value">
                            <ul>
                                <li>
                                    <label class="checkbox-design">
                                        <input type="checkbox" name="humms">
                                        <span class="checkbox-square"></span>Hummus
                                    </label>
                                </li>
                                <li>
                                    <label class="checkbox-design">
                                        <input type="checkbox" name="ep">
                                        <span class="checkbox-square"></span>Eggplant
                                    </label>
                                </li>
                                <li>
                                    <label class="checkbox-design">
                                        <input type="checkbox" name="gb">
                                        <span class="checkbox-square"></span>Green Salad
                                    </label>
                                </li>
                                <li>
                                    <label class="checkbox-design">
                                        <input type="checkbox" name="tabo">
                                        <span class="checkbox-square"></span>Tabouleh
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="variation_detail">
                        <div class="variation_title">
                            <h5 class="variation_name">Choice of Large Side</h5>
                            <p class="variation-status">Required</p>
                        </div>
                        <div class="variation-value">
                            <ul>
                                <li>
                                    <label class="checkbox-design">
                                        <input type="checkbox" name="humms">
                                        <span class="checkbox-square"></span>Mutabel<span class="vprice">$2.50</span>
                                    </label>
                                </li>
                                <li>
                                    <label class="checkbox-design">
                                        <input type="checkbox" name="ep">
                                        <span class="checkbox-square"></span>Eggplant Dip<span class="vprice">$2.50</span>
                                    </label>
                                </li>
                                <li>
                                    <label class="checkbox-design">
                                        <input type="checkbox" name="gb">
                                        <span class="checkbox-square"></span>Red Chilly
                                    </label>
                                </li>
                                <li>
                                    <label class="checkbox-design">
                                        <input type="checkbox" name="tabo">
                                        <span class="checkbox-square"></span>Musturd<span class="vprice">$2.50</span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="variation_detail">
                        <div class="variation_title">
                            <h5 class="variation_name">Choose a Size</h5>
                            <p class="variation-status">Required</p>
                        </div>
                        <div class="variation-value">
                            <ul>
                                <li>
                                    <label class="radio-design">
                                    <input type="radio" name="lang">
                                    <span class="radio-circle"></span>3Pc
                                    </label>
                                </li>
                                <li>
                                    <label class="radio-design">
                                    <input type="radio" name="lang">
                                    <span class="radio-circle"></span>5Pc
                                    </label>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div class="variation_detail">
                        <div class="variation_title">
                            <h5 class="variation_name">Special Instruction</h5>
        
                        </div>
        
                        <div class="variation-value">
                            <textarea maxlength="80" class="J9aW_"></textarea>
                                
                    
                        </div>
                    </div>
                </div>
            </div>
            <div class="vaddcart_box">
                <div class="input-group-qty"><span class="minus input-group-addon left-adon" data-direction="prev"><i class="fa fa-minus"></i></span> <input id="product_enteredQuantity_31" class="qty-input" type="text" aria-label="Enter a quantity" data-val="true" data-val-required="The Qty field is required." name="addtocart_31.EnteredQuantity" value="1"> <span class="plus input-group-addon right-adon" data-direction="next"><i class="fa fa-plus"></i></span></div>
                <div class="buttons"><a href="productadded.html"><input type="button" value="Add to cart" class="button-2 product-box-add-to-cart-button" onclick="productadded.html"></a></div>
            </div>
        </div>
    </div>
    <!-- working product popup finish-->
    </div>