<div class="screensize">
    <img src="assets/images/neworder.png">
    <div class="ordertype-heading">
      How can we serve you today? 
    </div>
    <div class="ordertype_option">
    <div class="ordertype-select_box"><a [routerLink]="['/home']"><img src="assets/images/dinein.png"><span class="ordertype_title">Dine In</span></a></div>
    <div class="ordertype-select_box"><a [routerLink]="['/home']"><img src="assets/images/takeaway.png"><span class="ordertype_title">Take Away</span></a></div>
    </div>
    <span class="ordertype_notification">Don’t worry you can change this later</span>
</div>